.Heading {
    text-decoration: solid;
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.rootcontainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 100px auto;
}
.buttons {
    margin-top: 50px;
}
