sup {
    top: 0  !important;
}

.mkolj{
    font-family: cursive;
}
.asjdbasdvijasd{
    width: 371px;
    font-family:   cursive ;
    height: 38px;
    margin-left: 10px;
    margin-top: 11px;
    border-radius: 17px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}   
.safdfda{
    background-color: wheat;
    position: relative;
    width: 50px;
    right: 40px;
    font-size: 1.3rem;
}
.jasdhiavjf{
    font-size: 1.3rem;
    font-family: cursive;
    margin-left: 8px;
    margin-right: 20px;
}
.asdjasbk{
    width: 30px;
    height: 30px;
    border-radius: 50px;
}
.flex-container {
	display: flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-box;
	display: -webkit-flex;
	justify-content: space-around;
	list-style: none;
	margin: 0;
	padding: 0;
	-webkit-flex-flow: row wrap;
	position: relative;
}
.flex-item {
	color: #fff;
	height: 210px;
	margin: 30px 0;
	padding: 0;
	width: 225px;
	position: relative;
}
/* .  {
	display: flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-box;
	display: -webkit-flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 210px;
	margin: 0;
	padding: 0;
	width: 100%;
} */


/*-------- cards --------*/
.card-front,
.card-back {
	position: absolute;
	top: 0;
    border-radius: 15px;
	left: 0;
	width: 185px;
	height: 181px;
	padding: 20px;
	margin: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	justify-content: flex-end;
}
.card-front i.tile-icon {
	display: block;
	margin: 10px auto 20px auto;
	text-align: center;
}
.card-front h4 {
	font-size: 18px;
	font-weight: normal;
	margin: 10px 0;
}
.card-front p.detail {
	font-size: 24px;
	font-weight: bold;
	margin: 0;
}
.card-front.bg-violet {
	background-color: #702082;
}
.card-front.bg-magenta {
	background-color: #c110a0;
}
.card-front.bg-blue {
	background-color: #00a0d2;
}
.card-front.bg-green {
	background-color: #00c389;
}
.card-back {
	background-color: #1e1e1e;
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
}
.card-back p.title {
	color: #000;
	margin-top: 0;
	text-transform: uppercase;
}
.card-back p.desc {
	color: #63666a;
}
.card-back p.link {
	color: #702082;
}
.card-back.bg-violet {
	background-color: #d8d7df;
}
.card-back.bg-magenta {
	background-color: #ddd0cf;
}
.card-back.bg-blue {
	background-color: #c8d7df;
}
.card-back.bg-green {
	background-color: #d9e6dc;
}


.scnd-font-color {
    color: #9099b7;
}
.titular {
display: block;
line-height: 60px;
margin: 0;
text-align: center;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
}
.horizontal-list {
margin: 0;
padding: 0;
list-style-type: none;
}
.horizontal-list li {
    float: left;
}
    .block22 {
        margin: 25px 25px 0 0;
        background: #394264;
        border-radius: 5px;
  float: left;
  width: 400px;
  height: 398px;
  overflow: hidden;
    }
    /******************************************** LEFT CONTAINER *****************************************/
    .left-container {}
        .menu-box {
            height: 360px;
        }

        .donut-chart-block {
            overflow: hidden;
            border-radius: 30px;
        }
            .donut-chart-block .titular {
                padding: 10px 0;
            }
            .os-percentages li {
                width: 96px;
                /* border-left: 1px solid #394264; */
                text-align: center;					
                /* background: #50597b; */
            }
                .os {
                    margin: 0;
                    padding: 10px 0 5px;
                    font-size: 15px;		
                }
                    .os.ios {
                        border-top: 4px solid #e64c65;
                    }
                    .os.mac {
                        border-top: 4px solid #11a8ab;
                    }
                    .os.linux {
                        border-top: 4px solid #fcb150;
                    }
                    .os.win {
                        border-top: 4px solid #4fc4f6;
                    }
                .os-percentage {
                    margin: 0;
                    padding: 0 0 15px 10px;
                    font-size: 25px;
                }
        .line-chart-block, .bar-chart-block {
            height: 400px;
            border-radius: 30px;
        }
            .line-chart {
                height: 200px;
                background: #11a8ab;
            }
            .time-lenght {
                padding-top: 22px;
                padding-left: 38px;
      overflow: hidden;
            }
                .time-lenght-btn {
                    display: block;
                    width: 70px;
                    line-height: 32px;
                    background: #50597b;
                    border-radius: 5px;
                    font-size: 14px;
                    text-align: center;
                    margin-right: 5px;
                    -webkit-transition: background .3s;
                    transition: background .3s;
                }
                    .time-lenght-btn:hover {
                        text-decoration: none;
                        background: #e64c65;
                    }
            .month-data {
                padding-top: 28px;
            }
                .month-data p {
                    display: inline-block;
                    margin: 0;
                    padding: 0 25px 15px;            
                    font-size: 16px;
                }
                    .month-data p:last-child {
                        padding: 0 25px;
          float: right;
                        font-size: 15px;
                    }
                    .increment {
                        color: #e64c65;
                    }

/******************************************
↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓
ESTILOS PROPIOS DE LOS GRÄFICOS
↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ 
GRAFICO LINEAL
******************************************/

.grafico {
padding: 2rem 1rem 1rem;
width: 100%;
height: 100%;
position: relative;
color: #fff;
font-size: 80%;
}
.grafico span {
display: block;
position: absolute;
bottom: 3rem;
left: 2rem;
height: 0;
border-top: 2px solid;
transform-origin: left center;
}
.grafico span > span {
left: 100%; bottom: 0;
}
[data-valor='25'] {width: 75px; transform: rotate(-45deg);}
[data-valor='8'] {width: 24px; transform: rotate(65deg);}
[data-valor='13'] {width: 39px; transform: rotate(-45deg);}
[data-valor='5'] {width: 15px; transform: rotate(50deg);}
[data-valor='23'] {width: 69px; transform: rotate(-70deg);}
[data-valor='12'] {width: 36px; transform: rotate(75deg);}
[data-valor='15'] {width: 45px; transform: rotate(-45deg);}

[data-valor]:before {
content: '';
position: absolute;
display: block;
right: -4px;
bottom: -3px;
padding: 4px;
background: #fff;
border-radius: 50%;
}
[data-valor='23']:after {
content: '+' attr(data-valor) '%';
position: absolute;
right: -2.7rem;
top: -1.7rem;
padding: .3rem .5rem;
background: #50597B;
border-radius: .5rem;
transform: rotate(45deg);  
}
[class^='eje-'] {
position: absolute;
left: 0;
bottom: 0rem;
width: 100%;
padding: 1rem 1rem 0 2rem;
height: 80%;
}
.eje-x {
height: 2.5rem;
}
.eje-y li {
height: 25%;
border-top: 1px solid #777;
}
[data-ejeY]:before {
content: attr(data-ejeY);
display: inline-block;
width: 2rem;
text-align: right;
line-height: 0;
position: relative;
left: -2.5rem;
top: -.5rem;
} 
.eje-x li {
width: 33%;
float: left;
text-align: center;
}

/******************************************
GRAFICO CIRCULAR PIE CHART
******************************************/
.donut-chart {
position: relative;
width: 200px;
height: 200px;
margin: 0 auto 2rem;
border-radius: 100%
}
p.center-date {
background: #394264;
position: absolute;
text-align: center;
font-size: 28px;
top:0;left:0;bottom:0;right:0;
width: 130px;
height: 130px;
margin: auto;
border-radius: 50%;
line-height: 35px;
padding: 15% 0 0;
}
.center-date span.scnd-font-color {
line-height: 0; 
}
.recorte {
border-radius: 50%;
clip: rect(0px, 200px, 200px, 100px);
height: 100%;
position: absolute;
width: 100%;
}
.quesito {
border-radius: 50%;
clip: rect(0px, 100px, 200px, 0px);
height: 100%;
position: absolute;
width: 100%;
font-family: monospace;
font-size: 1.5rem;
}
#porcion1 {
transform: rotate(0deg);
}

#porcion1 .quesito {
background-color: #E64C65;
transform: rotate(76deg);
}
#porcion2 {
transform: rotate(76deg);
}
#porcion2 .quesito {
background-color: #11A8AB;
transform: rotate(140deg);
}
#porcion3 {
transform: rotate(215deg);
}
#porcion3 .quesito {
background-color: #4FC4F6;
transform: rotate(113deg);
}
#porcionFin {
transform:rotate(-32deg);
}
#porcionFin .quesito {
background-color: #FCB150;
transform: rotate(32deg);
}
.nota-final {
clear: both;
color: #4FC4F6;
font-size: 1rem;
padding: 2rem 0;
}
.nota-final strong {
color: #E64C65;
}
.nota-final a {
color: #FCB150;
font-size: inherit;
}
/**************************
BAR-CHART
**************************/
.grafico.bar-chart {
background: #3468AF;
padding: 0 1rem 2rem 1rem;
width: 100%;
height: 60%;
position: relative;
color: #fff;
font-size: 80%;
}
.bar-chart [class^='eje-'] {
padding: 0 1rem 0 2rem;
bottom: 1rem;
}
.bar-chart .eje-x {
bottom: 0;
}
.bar-chart .eje-y li {
height: 20%;
border-top: 1px solid #fff;
}
.bar-chart .eje-x li {
width: 14%;
position: relative;
text-align: left;
}
.bar-chart .eje-x li i {
transform: rotatez(-45deg) translatex(-1rem);
transform-origin: 30% 60%;
display: block;
}
.bar-chart .eje-x li:before {
content: '';
position: absolute;
bottom: 1.9rem;
width: 70%;
right: 5%;
box-shadow: 3px 0 rgba(0,0,0,.1), 3px -3px rgba(0,0,0,.1);
}
.bar-chart .eje-x li:nth-child(1):before {
background: #E64C65;  
height: 570%;
}
.bar-chart .eje-x li:nth-child(2):before {
background: #11A8AB;  
height: 900%;
}
.bar-chart .eje-x li:nth-child(3):before {
background: #FCB150;  
height: 400%;
}
.bar-chart .eje-x li:nth-child(4):before {
background: #4FC4F6;  
height: 290%;
}
.bar-chart .eje-x li:nth-child(5):before {
background: #FFED0D;  
height: 720%;
}
.bar-chart .eje-x li:nth-child(6):before {
background: #F46FDA;  
height: 820%;
}
.bar-chart .eje-x li:nth-child(7):before {
background: #15BFCC;  
height: 520%;
}

.asdasdasdasdasdas{
    background-color: #161717;
}
.asddasdasdasdasdas{
    height: 58vmax;
}
.mkbdsds{
    /* border: 1px solid white; */
    width: 150px;
    margin: auto;
}
.dasdsd{
    font-family: serif;
    font-size: 3rem;
}
.elokpkk{
    position: absolute;
    top: 20px;
}
.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.file-input{
      margin: 32px;

  }
  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #4245a8;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  
  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }
  
  .image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .file-label {
    display: inline-block;
    padding: 10px;
    background-color: #3498db;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .file-input {
    display: none;
  }
  
  .upload-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #2ecc71;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .selected-images {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .uploaded-image {
    max-width: 150px;
    max-height: 150px;
    margin: 10px;
  }
  
.AdminPhoto {
    width: 50%;
}

.users {
    width: 40%;
    border: 1px solid black;
    height: 20%;
    margin-top: 20px;

}
 
.adminImage {
    display: flex;
    justify-content: flex-end;
    margin: 0%;
    padding: 0%;
}

.admininfo {
    display: inline-block;
    justify-content: flex-start;
}
.zifo {
    margin-top: 20px;
}



