.dasfasfkasfhoiasbfbfa{
    font-size: 3.5rem;
    font-family: cursive;
    /* background: ; */
    background-color: rgb(161, 161, 161);
}
/* .lllololo:hover{
cursor: pointer;
}
.lllololo{
    display: none;
} */
.imagedis {
    width: 140px;
    height: 110px;
}