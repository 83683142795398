.form-main {

}
  .form-left {
    margin-top: 20px;
    width: 50%;
    height: 50%;
  }
  
  .left-images {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 0px 10px 20px 20px;
  }
  .img-one img {
    margin-top: -90px;
    width: 700px;
    height: 700px;
    /* margin-bottom: -110px; */
    /* margin-bottom: 200px; */
  }
  .img-two {
    /* padding-bottom: 50px; */
  }
  .form-wrapper {
    display: flex;
    justify-content: space-aroundٖ;
  }
  
  .form-right {
    margin: 20vh auto;
  }
  
  .div-main-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: black;
    background-color: aliceblue;
    padding: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 10px;
  }
  .div-main-form h2 {
    font-size: 20px;
    font-weight: 800;
  }
  .div-main-form input {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    font-family: 200;
    color: black;
  }
  .Signup-btn {
    background-image: linear-gradient(to right, #868f96 0%, #596164 100%);
    padding: 10px;
    border-radius: 10px;
    font-weight: 800;
  }
  .Signup-btn:hover {
    background-image: linear-gradient(45deg, #8baaaa 0%, #ae8b9c 100%);
  }
  