@import url('https://fonts.googleapis.com/css?family=Montserrat|Quicksand');

.form-modal {
    position: relative;
    width: 450px;
    height: auto;
    margin-top: 4em;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
}

.form-modal button {
    cursor: pointer;
    position: relative;
    text-transform: capitalize;
    font-size: 1em;
    z-index: 2;
    outline: none;
    background: #fff;
    transition: 0.2s;
}

.form-modal .btn {
    border-radius: 20px;
    border: none;
    font-weight: bold;
    font-size: 1.2em;
    padding: 0.8em 1em 0.8em 1em!important;
    transition: 0.5s;
    border: 1px solid #ebebeb;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.form-modal .login,
.form-modal .signup {
    background:rgb(236, 12, 54);
    color: #fff;
}

.form-modal .login:hover,
.form-modal .signup:hover {
    background: #222;
}

.form-toggle {
    position: relative;
    width: 100%;
    height: auto;
}

.form-toggle button {
    width: 50%;
    float: left;
    padding: 1.5em;
    margin-bottom: 1.5em;
    border: none;
    transition: 0.2s;
    font-size: 1.1em;
    font-weight: bold;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.form-toggle button:nth-child(1) {
    border-bottom-right-radius: 20px;
}

.form-toggle button:nth-child(2) {
    border-bottom-left-radius: 20px;
}

#login-toggle {
    background:rgb(236, 12, 54);
    color: #ffff;
}

.form-modal form {
    position: relative;
    width: 90%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
}

#login-form,
#signup-form {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 2rem ;
    padding-bottom: 2rem;
}

#signup-form {
    display: none;
}

#login-form button,
#signup-form button {
    width: 100%;
    margin-top: 0.5em;
    padding: 0.6em;
}

.form-modal input {
    position: relative;
    width: 100%;
    font-size: 1em;
    padding: 1.2em 1.7em 1.2em 1.7em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    border-radius: 20px;
    border: none;
    background: #ebebeb;
    outline: none;
    font-weight: bold;
    transition: 0.4s;
}

.form-modal input:focus,
.form-modal input:active {
    transform: scaleX(1.02);
}

.form-modal input::-webkit-input-placeholder {
    color: #222;
}

.form-modal p {
    font-size: 16px;
    font-weight: bold;
}

.form-modal p a {
    color:rgb(236, 12, 54);
    text-decoration: none;
    transition: 0.2s;
}

.form-modal p a:hover {
    color: #222;
}

.form-modal i {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateX(-10%) translateY(-50%);
}

.fa-google {
    color: #dd4b39;
}

.fa-linkedin {
    color: #3b5998;
}

.fa-windows {
    color: #0072c6;
}

.-box-sd-effect:hover {
    box-shadow: 0 4px 8px hsla(210, 2%, 84%, .2);
}

@media only screen and (max-width: 500px) {
    .form-modal {
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    i {
        display: none!important;
    }
}


.error {
    border: 1px solid red;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }
  