@media (max-width: 767px) {
  .navicon {
    width: 1.125em;
    height: .125em;
  }

  .navicon::before,
  .navicon::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all .2s ease-out;
    content: '';
    background: white;
  }

  .navicon::before {
    top: 5px;
  }

  .navicon::after {
    top: -5px;
  }

  .menu-btn:not(:checked) ~ .menu {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    display: block;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon::before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::before,
  .menu-btn:checked ~ .menu-icon .navicon::after {
    top: 0;
  }
}
/* Apply the initial styles to the navigation items */
.menu {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 0;
  padding: 0.5rem 1rem;
  position: relative;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  z-index: 1;
}

.menu li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d6b02e;
  transform-origin: top right;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  z-index: -1;
}

.menu li:hover {
  color: black;
  transform: translateY(-2px);
}

.menu li:hover:before {
  transform: scaleX(1);
  transform-origin: top left;
}

.menu li.active:before {
  transform: scaleX(1);
  transform-origin: top left;
}

.menu li i.fa-user {
  font-size: 1.2rem;
}

.menu li:last-child {
  margin-right: 0;
}

.menu button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  color: black;
  background-color: #d6b02e;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.menu button:hover {
  background-color: #d6b02e;
  transform: translateY(-2px);
}
.itmsHlo{
  /* background-color: white; */
}
.search-container{
  display: flex!important;
  flex-direction: column!important;
  position: relative;
  top: 20px;
}
.search-container > ul::-webkit-scrollbar 
{
  display: none;  /* Safari and Chrome */
}



.PrdouctSerchUl{
  height: 10rem;
overflow-x: hidden;
  /* border-radius: 5px; */
  overflow-y: scroll!important;
  width: 100%;
  overflow: scroll;
  background-color: rgb(228, 225, 225);
}
.searchbot {
  margin-bottom: 40px;

}
.flagimg {
  width: 2px;
  height: 2px;
}
.bgflag {
  background-image: url('../../Images/usa.png');
}


.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  cursor: pointer;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #d6b02e;}