.heads {
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    background-color: #3D4852;
    font-size: 20px;
}
.subhovers:hover {

    background-color: #f8eeec;
    cursor: pointer;

}