.rootcontainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
}
.Heading {
    
    text-decoration: solid;
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.rootcontainers {
  display: inline;
  width: 100%;
  gap: 20px;
  text-align: center;
  margin: 20px auto;
}
.Headings {
  text-decoration: solid;
  
  font-size: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.error {
    border: 1px solid red;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }