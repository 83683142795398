.search-container {
margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
}

.search-input {
  border: none;
  padding: 10px;
  border-radius: 6px 0 0 6px;
  font-size: 16px;
  width: 900px;
}

.search-button {
  background-color: rgb(236, 12, 54);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.search-dropdown-container {
  display: flex;
  align-items: start;
  border: 1px solid #ccc;
  border-radius: 6px 0 0 6px;
  background-color: white;
  overflow: hidden;
}

.search-dropdown {
  padding: 10px;
  font-size: 16px;
  width: 150px; /* Adjust the width as needed */
  border: none;
  background-color: transparent;
}

.search-input {
  border: none;
  padding: 10px;
  font-size: 16px;
  width: 300px;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 5px;
  display: none;
  transition: opacity 0.3s ease-in-out;
}

.popup.visible {
  display: block;
}

.popup-content {
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
